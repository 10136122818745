import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import {
  Archive,
  Banner,
  Head,
  Layout,
  Stars,
  Testimonial,
  Button,
} from "../components"

export const TestimonialListPageTemplate = ({
  title,
  total,
  basePath,
  currentPage,
  testimonials,
}) => (
  <>
    <Banner interior title={title}>
      <Stars variant="inside-banner" />
      <Button
        tag="a"
        href="https://g.page/cpi85"
        target="_blank"
        rel="noreferrer noopener"
        style={{ marginTop: 30 }}
      >
        Laisser un avis
      </Button>
    </Banner>
    <Archive total={total} currentPage={currentPage} basePath={basePath}>
      {testimonials.map(({ node }) => {
        const testimonial = node.frontmatter
        return (
          <Testimonial
            key={`t-${testimonial.title}-${testimonial.date}`}
            title={testimonial.title}
            tags={testimonial.tags}
            content={testimonial.content}
            author={testimonial.author}
            date={testimonial.date}
          />
        )
      })}
    </Archive>
  </>
)

TestimonialListPageTemplate.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.any,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  basePath: PropTypes.string,
  testimonials: PropTypes.array,
}

const TestimonialListPage = ({ data, pageContext }) => {
  const testimonials = data.allMarkdownRemark.edges
  const title = "Nos clients témoignent"
  const { total, currentPage, basePath } = pageContext
  return (
    <Layout>
      <Head title={title} />
      <TestimonialListPageTemplate
        title={title}
        total={total}
        currentPage={currentPage}
        testimonials={testimonials}
        basePath={basePath}
      />
    </Layout>
  )
}

TestimonialListPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default TestimonialListPage

export const pageQuery = graphql`
  query testimonialListPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { templateKey: { eq: "testimonial" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            content
            author
            date(formatString: "DD/MM/YYYY")
          }
        }
      }
    }
  }
`
